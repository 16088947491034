@import '../../sass/variables';
@import '../../sass/mixins';

.fallbackBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.fallbackContainer {
    width: 300px;
    height: 250px;
    padding: 16px;
    background-color: $whiteBackgroundColor;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: $desktop) {
        width: 400px;
        height: 300px;
    }
}

.alarmIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: $alarmColor;
    border-radius: 10px;

    margin-bottom: 8px;

    @media screen and (min-width: $desktop) {
        width: 40px;
        height: 40px;
    }
}

.alarmIcon {
    width: 20px;
    height: 20px;
    fill: $alarmColor;

    @media screen and (min-width: $desktop) {
        width: 24px;
        height: 24px;
    }
}

.fallbackTitle {
    @include text-style(24px, 800, 28px, $activeTextColor90);
    margin-bottom: 12px;

    @media screen and (min-width: $desktop) {
        @include text-style(28px, 800, 32px, $activeTextColor90);
    }
}


.detailsToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include text-style(13px, 500, 20px, $activeUnderlineColor);

    .arrowIcon {
        width: 16px;
        height: 16px;
        margin-left: 7px;
    }

    .expanded {
        transform: rotate(180deg);
        fill: $activeUnderlineColor;
    }
}

.fallbackMessage {
    margin-top: 12px;
    border-radius: 12px;
    @include text-style(11px, 500, 16px, $primaryTextColor);

    @media screen and (min-width: $desktop) {
        @include text-style(13px, 500, 20px, $primaryTextColor);
    }
}

.hiddenSummary {
    display: none;
}