// Breakpoints
$mobile: 320px;
$desktop: 1440px;

// Primary colors
$mainTextColor: rgba(240, 240, 240, 1); // Main text color (can be used for light text on dark backgrounds)
$mainBackgroundColor: rgba(246, 246, 246, 1); // Main background color (can be used for dark background elements)
$whiteBackgroundColor: rgba(255, 255, 255, 1); // White background color

// Text colors
$primaryTextColor: rgba(27, 31, 59, 0.65); // Primary text color (used for most of the text)
$activeTextColor90: rgba(25, 28, 48, 0.9); // Active text color (used for active or selected text elements)
$activeTextColor56: rgba(0, 0, 0, 0.56); // Active text color (56% opacity)

// UI colors
$activeUnderlineColor: rgba(82, 110, 211, 1); // Color for underline in active state (e.g., under links)
$modalOverlayColor: rgba(82, 110, 211, 0.12); // Overlay color for modals
$lightBorderColor: rgba(224, 224, 224, 1); // Light border color (can be used for borders and dividers)
$lightShadowColor: rgba(0, 0, 0, 0.1); // Light shadow color (used for subtle shadows)
$checkBoxColor: rgba(244, 244, 244, 1); // Checkbox background color
$redColor: rgba(255, 59, 48, 1); // Red color
$selectedRowColor: rgba(255, 232, 224, 1); // Selected row background color
$iconGreyColor: rgba(176, 176, 176, 1); // Icon grey color
$yellowColor: rgba(255, 199, 0, 0.12); // Yellow color with low opacity
$alarmColor: rgba(244, 87, 37, 0.12); // Alarm background color
$allColor: rgba(218, 179, 249, 0.12); // All color
$orangeColor: rgba(255, 138, 0, 0.12);
$errorColor: rgba(221, 76, 30, 1); // Error color
$greenBackgroundColor: rgba(74, 201, 155, 0.12); // Green background color
$blueBackgroundColor: rgba(112, 182, 246, 0.12); // Blue background color
$greenStatusColor: rgba(74, 201, 155, 1); // Green status color
$yellowStatusColor: rgba(255, 199, 0, 1); // Yellow status color
$blueStatusColor: rgba(112, 182, 246, 1); // Blue status color
$redStatusColor: rgba(244, 87, 37, 1); // Red status color
$allStatusColor: rgba(218, 179, 249, 1); // All color
$orangeStatusColor: rgba(255, 138, 0, 1);
$lightBackgroundColor: rgba(27, 31, 59, 0.4); // Light background color (40% opacity)
$hoverGreenColor: rgba(123, 193, 72, 1); // Hover green color
$hoverButtonColor: rgba(236, 236, 236, 1); // Hover button color

// Shadows
$shadows: (light: 0px 3px 6px 0px rgba(0, 0, 0, 0.05),
    medium: 0px 11px 11px 0px rgba(0, 0, 0, 0.04),
    heavy: 0px 25px 15px 0px rgba(0, 0, 0, 0.03),
);
$inputShadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1); // Shadow for input elements

// Exports
:export {
    mainTextColor: $mainTextColor;
    desktop: $desktop;
    greenStatusColor: $greenStatusColor;
    yellowStatusColor: $yellowStatusColor;
    blueStatusColor: $blueStatusColor;
    redStatusColor: $redStatusColor;
}