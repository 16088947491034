@import '../../../../sass/variables';
@import '../../../../sass/mixins';

.skeletonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.skeletonContainer {
    background-color: $whiteBackgroundColor;
    padding: 32px 24px;
    border-radius: 16px;
    width: 300px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $desktop) {
        width: 400px;
    }
}

.skeletonItemLarge {
    align-self: center;
    width: 220px;
    height: 24px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    margin-bottom: 20px;
    animation: skeleton-loading 1.2s infinite;
}

.skeletonItems {
    align-self: center;
}

.skeletonItemSmall {
    width: 60px;
    height: 16px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    margin-bottom: 8px;
    animation: skeleton-loading 1.2s infinite;
}

.skeletonItem {
    width: 260px;
    height: 50px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    margin-bottom: 12px;
    animation: skeleton-loading 1.2s infinite;

    @media screen and (min-width: $desktop) {
        width: 300px;
        margin-bottom: 16px;
    }
}

.skeletonItemLargeFooter {
    align-self: center;
    width: 200px;
    height: 50px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    animation: skeleton-loading 1.2s infinite;
}

@keyframes skeleton-loading {
    0% {
        background-color: $mainBackgroundColor;
    }

    50% {
        background-color: $whiteBackgroundColor;
    }

    100% {
        background-color: $mainBackgroundColor;
    }
}