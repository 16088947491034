@import '../../../../sass/variables';
@import '../../../../sass/mixins';

.skeletonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.skeletonHeader {
    background-color: $whiteBackgroundColor;
    padding: 17px 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

.skeletonHeaderItemsSmall {
    display: flex;
}

.skeletonHeaderItemSmallAdd {
    display: none;

    @media screen and (min-width: $desktop) {
        display: block;
    }
}

.skeletonHeaderItemSmall {
    height: 30px;
    width: 60px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    animation: skeleton-loading 1.2s infinite;

    &:not(:first-child) {
        margin-left: 12px;
    }
}

.skeletonHeaderItemLarge {
    height: 30px;
    width: 110px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    animation: skeleton-loading 1.2s infinite;
}

.skeletonBody {
    background-color: $whiteBackgroundColor;
    padding: 24px 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0px 8px 100px 8px;

    @media screen and (min-width: $desktop) {
        margin: 0px 230px 160px 230px;
    }
}

.skeletonRow {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $desktop) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.skeletonRowSmall {
    height: 30px;
    width: 110px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    margin-bottom: 9px;
    animation: skeleton-loading 1.2s infinite;

    @media screen and (min-width: $desktop) {
        margin-bottom: 0;
    }
}

.skeletonItemsRow {
    display: flex;
    margin-bottom: 16px;

    @media screen and (min-width: $desktop) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.skeletonItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skeletonItemSmall {
    width: 140px;
    height: 30px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    animation: skeleton-loading 1.2s infinite;

    &:not(:first-child) {
        margin-left: 6px;
    }
}

.skeletonItem {
    width: 280px;
    height: 200px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    animation: skeleton-loading 1.2s infinite;

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    @media screen and (min-width: $desktop) {
        width: 100%;
        height: 200px;
    }
}

.skeletonItemLarge {
    width: 280px;
    height: 100px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    animation: skeleton-loading 1.2s infinite;

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    @media screen and (min-width: $desktop) {
        width: 100%;
        height: 120px;
        margin-bottom: 0;

        &:nth-child(1) {
            margin-right: auto;
        }
    }
}

.skeletonItemsRowFooter {
    margin-top: 11px;

    @media screen and (min-width: $desktop) {
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

.skeletonItemLargeFooter {
    width: 270px;
    height: 30px;
    border-radius: 16px;
    background-color: $mainBackgroundColor;
    animation: skeleton-loading 1.2s infinite;

    &:not(:last-child) {
        margin-bottom: 24px;

        @media screen and (min-width: $desktop) {
            margin-bottom: 0;
            margin-right: 100px;
        }
    }
}

@keyframes skeleton-loading {
    0% {
        background-color: $mainBackgroundColor;
    }

    50% {
        background-color: $whiteBackgroundColor;
    }

    100% {
        background-color: $mainBackgroundColor;
    }
}