@import '../../../../sass/variables';
@import '../../../../sass/mixins';

.skeletonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.skeletonHeader {
    background-color: $whiteBackgroundColor;
    padding: 17px 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

.skeletonHeaderItemsSmall {
    display: flex;
}

.skeletonHeaderItemSmallAdd {
    display: none;

    @media screen and (min-width: $desktop) {
        display: block;
    }
}

.skeletonHeaderItemSmall {
    display: flex;
    gap: 12px;
    height: 30px;
    width: 60px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    animation: skeleton-loading 1.2s infinite;
}

.skeletonHeaderItemLarge {
    height: 30px;
    width: 110px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    animation: skeleton-loading 1.2s infinite;
}

.skeletonBody {
    background-color: $whiteBackgroundColor;
    padding: 24px 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0px 8px 100px 8px;

    @media screen and (min-width: $desktop) {
        margin: 0px 230px 160px 230px;
        padding: 32px 32px;
    }
}

.skeletonRow {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $desktop) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.skeletonRowSmall {
    height: 30px;
    width: 110px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    margin-bottom: 9px;
    animation: skeleton-loading 1.2s infinite;

    @media screen and (min-width: $desktop) {
        margin-bottom: 0;
    }
}

.skeletonItemsRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 6px;

    @media screen and (min-width: $desktop) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.skeletonItemsContainer {
    display: flex;
    justify-content: center;
}

.skeletonItems {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;

    @media screen and (min-width: $desktop) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.skeletonItemsFlex {
    display: flex;
    justify-content: space-between;
    width: 275px;
    gap: 6px;

    @media screen and (min-width: $desktop) {
        width: auto;
    }
}

.skeletonItemSmall {
    width: 104px;
    height: 30px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    animation: skeleton-loading 1.2s infinite;
}

.skeletonHeaderItemMiddle {
    @extend .skeletonItemSmall;

    width: 130px;

    @media screen and (min-width: $desktop) {
        width: 104px;
    }
}

.skeletonHeaderItemLarge {
    @extend .skeletonItemSmall;
    width: 272px;

    @media screen and (min-width: $desktop) {
        width: 104px;
    }
}


.skeletonItem {
    width: 280px;
    height: 452px;
    background-color: $mainBackgroundColor;
    border-radius: 16px;
    animation: skeleton-loading 1.2s infinite;


    @media screen and (min-width: $desktop) {
        width: 450px;
        height: 296px;
    }
}

.skeletonItemsRowFooter {
    display: flex;
    justify-content: center;
    margin-top: 11px;

    @media screen and (min-width: $desktop) {
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

.skeletonItemLargeFooter {
    width: 270px;
    height: 30px;
    border-radius: 16px;
    background-color: $mainBackgroundColor;
    animation: skeleton-loading 1.2s infinite;

    @media screen and (min-width: $desktop) {
        width: 167px;
    }
}

@keyframes skeleton-loading {
    0% {
        background-color: $mainBackgroundColor;
    }

    50% {
        background-color: $whiteBackgroundColor;
    }

    100% {
        background-color: $mainBackgroundColor;
    }
}