// Flexbox Centering
@mixin flex-center {
    display: flex;
    align-items: center;
}

// Flexbox Column Layout
@mixin flex-column {
    display: flex;
    flex-direction: column;
}

// Text Styles
@mixin text-style($size, $weight: 500, $line-height: 20px, $color: $mainTextColor) {
    font-size: $size;
    font-weight: $weight;
    line-height: $line-height;
    color: $color;
}

// Link Styles
@mixin link-style {
    text-decoration: none;
    transition: color 0.3s ease;
}

// Box Shadow
@mixin box-shadow($type) {
    box-shadow: map-get($shadows, $type);
}

// Box Shadow Complex
@mixin box-shadow-complex {
    box-shadow: map-get($shadows, light),
        map-get($shadows, medium),
        map-get($shadows, heavy);
}