@import './variables';

// Стили для всех скроллбаров
::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: $lightBackgroundColor;
    border-radius: 20px;
}